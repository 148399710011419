import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react'
import { formChange } from '../../redux/common-actions/form-action';
import { getFormValue } from '../../utils/common-util';
import "../SMultiDropdown/dropdown.css"


class SDropdown extends Component {
    handleChange() {
        const { scope, name, dispatch, changeHook } = this.props;
        return (e, { value }) => {
            dispatch(formChange(scope, name, value));
            if (changeHook != null) {
                changeHook(value);
            }
        }
    }
    translateText(){
        const {name, scope, formReducer, title, items} = this.props;
        let placeHolder = getFormValue(formReducer, scope, name, title);
        let ret = null;
        if(placeHolder !== title){
            items.forEach((item)=>{
                if(item.value === placeHolder){
                    ret = item.text;
                }
            });
        }
        if(ret == null){
            ret = placeHolder;
        }
        return ret;
    }
    render() {
        const { items, withoutFrame } = this.props;
        let placeHolder = this.translateText();
        return <Fragment>
            {
                withoutFrame === true ?
                    <Dropdown fluid scrolling
                        placeholder={placeHolder} options={items} style={{ width: "100%" }} onChange={this.handleChange()} />
                    :
                    <Dropdown fluid selection scrolling
                        placeholder={placeHolder} options={items} style={{ width: "100%" }} onChange={this.handleChange()}  />
            }
        </Fragment>
    }
}

export default connect(state => {
    return state;
})(SDropdown)
