import { RENDER_CLEAR, RENDER_DATA, APPEND_RENDER_DATA } from "../../constants";

var _ = require("lodash")

const initState = {

}
export default function renderReducer(preState=initState,action){
    const {type, data} = action
	switch (type) {
        case RENDER_DATA : {
            var newState = {};
            newState[data.scope] = data.value;
            return Object.assign({}, preState, newState)
        }
        case RENDER_CLEAR : {
            let temp = {};
            temp[data.scope] = {}
            return Object.assign({}, preState, temp)
        }
        case APPEND_RENDER_DATA : {
            let temp = null;
            if(preState[data.scope] != null && preState[data.scope].results != null){
                temp = Object.assign({}, _.cloneDeep(preState[data.scope]));
            }
            if(temp == null){
                temp = {};
                temp[data.scope] = data.value;
                return Object.assign({}, preState, temp);
            }
            if(data.value != null && data.value.pager != null){
                temp.pager = data.value.pager;
            }
            if(data.value != null && data.value.results != null && Array.isArray(data.value.results)){
                temp.results = [...temp.results, ...data.value.results];
            }
            let obj = {};
            obj[data.scope] = temp;
            return Object.assign({}, preState, obj);
        }
        default:
			return preState
    }
}