import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import DatePicker from 'react-mobile-datepicker';
import { Button } from 'semantic-ui-react';
import { getFormValue } from '../../utils/common-util'
import { formChange } from '../../redux/common-actions/form-action';

let dateConfig = {
    'year': {
        format: 'YYYY',
        caption: 'Year',
        step: 1,
    },
    'month': {
        format: 'MM',
        caption: 'Mon',
        step: 1,
    },
    'date': {
        format: 'DD',
        caption: 'Day',
        step: 1,
    },
    'hour': {
        format: 'hh',
        caption: 'Hour',
        step: 1,
    },
    'minute': {
        format: 'mm',
        caption: 'Min',
        step: 1,
    },
    'second': {
        format: 'hh',
        caption: 'Sec',
        step: 1,
    },
}

class ADatePicker extends Component {

    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    handleCancel() {
        return () => {
            this.setState({ open: false });
        }
    }

    handleSelect() {
        const { dispatch, scope, name } = this.props;
        return (time) => {
            console.log(time);
            dispatch(formChange(scope, name, time.getTime()));
            this.setState({ open: false });
        }
    }

    render() {
        const { formReducer, scope, name, placeHolder } = this.props;
        let timestamp = getFormValue(formReducer, scope, name, 0);
        let str = placeHolder;
        if (timestamp > 0) {
            str = new Date(timestamp).Format("yyyy-MM-dd hh:mm:ss");
        }
        return (
            <Fragment>
                <Button primary onClick={() => { this.setState({ open: true }) }} size="tiny">{str}</Button>
                <DatePicker
                    value = {timestamp > 0 ? new Date(timestamp) : new Date()}
                    isOpen={this.state.open}
                    dateConfig={dateConfig}
                    onSelect={this.handleSelect()}
                    onCancel={this.handleCancel()} />
            </Fragment>
        )
    }
}

export default connect(state => {
    return state;
})(ADatePicker)
