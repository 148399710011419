import axios from 'axios'
// import { Modal } from 'antd'
import { getCookie } from '../../utils/common-util'
import {changeModalContent, changeModalDisplay} from '../common-actions/modal-action'
import { projectName } from '../../constants'

export const uploadFile = (url, file, callbackAction) => {
    return (dispatch) => {
        var data = new FormData();
        data.append("file", file);
        data.append("project", projectName);
        axios.post(
            url,
            data,
            // 第1个参数 url 第二参数 data数据，第三个是配置渲染，
            // onUploadProgress 当上传进度是
            {
                onUploadProgress: e => {
                    console.log("Upload progress", e.loaded / e.total * 100)
                }
            }
        )
            .then(res => {
                if (res && res.status === 200) {
                    let data = res.data;
                    if (callbackAction != null) {
                        callbackAction(data);
                    }
                } else {
                    // Modal.error({
                    //     content: "网络连接失败"
                    // })
                    alert("网络连接失败")
                }
            })
    }
}

export const sendJsonPost = (url, data, callbackAction, history, warning) => {
    return (dispatch) => {
        let token = getCookie("token");
        if (token == null) {
            token = ""
        }
        axios({
            headers: {
                'Content-Type': 'application/json',
                Authorization: token
            },
            method: "post",
            url: url,
            data: data
        }).then(
            res => {
                if (res && res.status === 200) {
                    // console.log("Send success!", res);
                    if (res.data.code !== 0) {
                        // Modal.error({
                        //     content: res.data.message,
                        //     onOk : ()=>{
                        //         if(res.data.code === 3 && history != null){
                        //             history.push("/login");
                        //         }
                        //     }
                        // })
                        // Modal.error({
                        //     content: "网络连接失败"
                        // })
                        if(warning === true){
                            dispatch(changeModalContent("networkWarning", res.data.message));
                            dispatch(changeModalDisplay("networkWarning", true));
                        }
                        if (res.data.code === 3 && history != null) {
                            history.push("/login");
                        }
                    }
                    callbackAction(res.data);
                } else {
                    if(warning === true){
                        dispatch(changeModalContent("networkWarning", "Network Error"));
                        dispatch(changeModalDisplay("networkWarning", true));
                    }
                }
            }
        );
    }
}