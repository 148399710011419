import React, { Component } from 'react'
import { connect } from 'react-redux'
import AInput from '../../components/AInput';
import { Button } from 'semantic-ui-react'
import { loginUserUrl } from '../../constants';
import {sendJsonPost} from '../../redux/common-actions/network-action'
import { setCookie, showErrorPrompt } from '../../utils/common-util';
import SModal from '../../components/SModal';

class Login extends Component {
    componentDidMount(){
        window.document.title = "Login";
    }
    handleLoginClick(){
        const {dispatch, history, formReducer} = this.props;
        return ()=>{
            dispatch(sendJsonPost(loginUserUrl, formReducer.login, (resp)=>{
                if(resp.code === 0){
                    setCookie("token", resp.message);
                    history.push("/main/product-list");
                }else{
                    showErrorPrompt(dispatch, resp);
                }
            }));
        }
    }
    render() {
        return (
            <div className="fullscreen-container">
                <div style={{ width: "95%", backgroundColor: "white", borderRadius: 5, justifyContent: "center", display: "flex" }}>
                    <div className="login-container">
                        <span className="login-title">Login</span>
                        <span className="form-title">Name</span>
                        <AInput scope="login" name="name" icon="user"/>
                        <span className="form-title">Password</span>
                        <AInput scope="login" name="pwd" icon="lock" password={true}/>
                        <Button className="login-button" primary onClick={this.handleLoginClick()}>Login</Button>
                    </div>
                </div>
                <SModal name="networkWarning" header="Network Warning"></SModal>
            </div>
        )
    }
}

export default connect(state => {
    return state;
})(Login)
