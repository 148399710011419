import React, { Component } from 'react'
import { Modal } from 'semantic-ui-react'
import { changeModalDisplay } from '../../redux/common-actions/modal-action';
import { getFormValue } from '../../utils/common-util';
import { connect } from 'react-redux';


class LoadingModal extends Component {
    render() {
        const { modalReducer, dispatch } = this.props;
        let display = getFormValue(modalReducer, "display", "loadingModal", false);
        return (
            <Modal
                closeOnDimmerClick={false}
                open={display}
                onClose={() => dispatch(changeModalDisplay("loadingModal", false))}>
                <Modal.Content>
                    <span>Loading please waiting...</span>
                </Modal.Content>
            </Modal>
        )
    }
}

export default connect(state => {
    return state;
})(LoadingModal)
