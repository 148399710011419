import React, { Component } from 'react'
import { connect } from 'react-redux';
import "./supload.css"
import { uploadFileUrl, uploadFilePrefixUrl } from '../../constants';
import { isArray } from 'lodash';
import { getFormValue, showErrorPrompt } from '../../utils/common-util';
import { uploadFile } from '../../redux/common-actions/network-action';
import { formChange } from '../../redux/common-actions/form-action';
import { resolveImagePath } from '../../utils/common-util';

class SUpload extends Component {

    buildFilterArray(filterType, filterArray) {
        if (filterType === "pic") {
            return ["jpg", "jpeg", "png", "bmp", "gif", "tiff"];
        } else if (filterType === "doc") {
            return ["doc", "docx", "pdf", "xls", "xlsx"];
        } else if (isArray(filterArray) && filterArray.length > 0) {
            return filterArray
        } else {
            return null;
        }
    }

    filterFile(fileName) {
        var index = fileName.lastIndexOf(".");
        if (index < 0) {
            return false;
        }
        var suffix = fileName.substr(index + 1);
        let filterArray = this.buildFilterArray();
        if (filterArray != null) {
            if (filterArray.indexOf(suffix.toLowerCase()) >= 0) {
                return true;
            }
            return false;
        } else {
            //No filtering
            return true;
        }
    }

    handleUpload() {
        const { dispatch, scope, name, multi, formReducer, handleFinish } = this.props;
        return (event) => {
            let file = event.target.files[0];
            if (file == null) {
                //Mean upload is canceled
                return;
            }
            if (!this.filterFile(file.name)) {
                showErrorPrompt(dispatch, null, "不支持的文件格式 : " + file);
            }
            dispatch(uploadFile(uploadFileUrl, file, (resp) => {
                if (resp.code === 0) {
                    console.log("Upload ret : ", resp);
                    let fileName = resp.result.fileName;
                    if (multi) {
                        let value = getFormValue(formReducer, scope, name, []);
                        let array = [];
                        if (isArray(value) && value.length > 0) {
                            array = [fileName, ...value];
                        } else {
                            array = [fileName];
                        }
                        dispatch(formChange(scope, name, array));
                        if(handleFinish != null){
                            handleFinish(null, array);
                        }
                    } else {
                        dispatch(formChange(scope, name, fileName));
                        if(handleFinish != null){
                            handleFinish(fileName);
                        }
                    }
                } else {
                    showErrorPrompt(dispatch, resp, null);
                }
            }))
        }
    }

    render() {
        const { multi, formReducer, scope, name } = this.props;
        let fileName = getFormValue(formReducer, scope, name);
        let src = "/cloud-upload.png";
        if (!multi && (fileName != null && fileName !== "")) {
            src = resolveImagePath(fileName);
        }
        return (
            <div className="s-upload">
                <input type="file" onChange={this.handleUpload()} />
                <img alt="account" src={src}></img>
            </div>
        )
    }
}


export default connect(state => {
    return state;
})(SUpload)