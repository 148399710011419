import { changeModalContent, changeModalDisplay } from "../redux/common-actions/modal-action";
import { uploadFilePrefixUrl } from '../constants'

export const checkEmpty = (entity, propArray) => {
    if (entity == null) {
        return false;
    }
    for(let i = 0 ; i < propArray.length ; i++){
        if (entity[propArray[i]] == null || entity[propArray[i]] === "") {
            return false;
        }
    }
    return true;
}

export const setCookie = function (name, value) {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
}

export const getCookie = function (name) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    arr = document.cookie.match(reg)
    if (arr != null)
        return unescape(arr[2]);
    else
        return null;
}

export const initRenderData = () => {
    return {
        results : [],
        pager : {totalPage : 0, currentPage : 0, totalCount : 0, customizedPageSize : 20, pageSize : 20}
    }
}

export const getFormValue = (formReducer, scope, name, defaultValue) =>{
    let formData = formReducer[scope];
    if(formData == null){
        return defaultValue;
    }else{
        let ret = formData[name];
        if(ret == null){
            return defaultValue;
        }else{
            return ret;
        }
    }
}

export const showErrorPrompt = (dispatch, resp, message) => {
    let content = message;
    if(content == null && resp != null){
        content = "Server Error, Code : " + resp.code + " Message :" + resp.message;
    }
    dispatch(changeModalContent("networkWarning", content));
    dispatch(changeModalDisplay("networkWarning", true));
}

const digitsRE = /(\d{3})(?=\d)/g

export const currencyFormat = (value, currency, decimals) => {
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  currency = currency != null ? currency : '$'
  decimals = decimals != null ? decimals : 2
  var stringified = Math.abs(value).toFixed(decimals)
  var _int = decimals
    ? stringified.slice(0, -1 - decimals)
    : stringified
  var i = _int.length % 3
  var head = i > 0
    ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
    : ''
  var _float = decimals
    ? stringified.slice(-1 - decimals)
    : ''
  var sign = value < 0 ? '-' : ''
  return sign + currency + head +
    _int.slice(i).replace(digitsRE, '$1,') +
    _float
}

export const zeroFloat = (value) => {
    let ret = 0;
    if(value != null){
        ret = parseFloat(value);
    }
    return ret;
}

export const zeroCurrency = (value, currency) =>{
    return currencyFormat(zeroFloat(value), currency, 2);

}

export const commonTransalate = (value, db, defaultValue) => {
    let ret = db[value];
    if(ret == null){
        return defaultValue;
    }
    return ret;
}

export const getListItem = (list, index, defaultValue, prefix) => {
    if(list == null || list.length - 1 < index){
        return defaultValue;
    }else{
        if(prefix != null){
            return prefix + list[index];
        }else{
            return list[index];
        }
    }
}

export const resolveImagePath = (fileName) =>{
    if(fileName != null && fileName !== ""){
        return uploadFilePrefixUrl + fileName;
    }else{
        return "/empty.jpg";
    }
}