import React, { Component } from 'react'

import { connect } from 'react-redux';

class WorkLogItem extends Component {
    render() {
        const {record} = this.props;
        return (
            <li className="li-card flex-column-div">
                <div className="card-line">
                    <span className="bold-span">
                        ID: {record.id}
                    </span>
                </div>
                <div className="card-line">
                    <span className="bold-span">
                        开始时间: {new Date(record.startTime).Format("yyyy-MM-dd hh:mm:ss")}
                    </span>
                </div>
                <div className="card-line">
                    <span className="bold-span">
                        结束时间: {new Date(record.endTime).Format("yyyy-MM-dd hh:mm:ss")}
                    </span>
                </div>
                <div className="card-line">
                    <span className="bold-span">工位: {
                    record.workStation == null ? "-" : record.workStation.name}
                </span>
                </div>
                <div className="card-line">
                    <span className="bold-span">工单: {record.orderId}</span>
                </div>
                <div className="card-line">
                    <span className="bold-span">加工数: {record.count}</span>
                </div>
            </li>
        )
    }
}

export default connect(state => {
    return state;
})(WorkLogItem)

