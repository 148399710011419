import { MODAL_DISPLAY, MODAL_LOADING, MODAL_CONTENT } from "../../constants";
const initState = {
    display : {},
    loading : {}
}
export default function modalReducer(preState=initState,action){
    const {type} = action
	switch (type) {
        case MODAL_DISPLAY : {
            let column = {};
            column[action.data.modalId] = action.data.display;
            let temp = Object.assign({}, preState.display, column);
            return Object.assign({}, preState, {display : temp})
        }
        case MODAL_LOADING : {
            let column = {};
            column[action.data.modalId] = action.data.loading;
            let temp = Object.assign({}, preState.loading, column);
            return Object.assign({}, preState, {loading : temp})
        }
        case MODAL_CONTENT : {
            let column = {};
            column[action.data.modalId] = action.data.content;
            let temp = Object.assign({}, preState.content, column);
            return Object.assign({}, preState, {content : temp})
        }
        default:
			return preState
    }
}