import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendJsonPost } from '../../redux/common-actions/network-action'
import { loadWorkstationUrl } from '../../constants';
import { renderData } from '../../redux/common-actions/render-action';
import BScroll from "better-scroll"
import WorkstationItem from './WorkstationItem';

class WorkstationList extends Component {
    componentDidMount(){
        this.loadWorkstationRecords()();
    }

    loadWorkstationRecords(){
        const {dispatch} = this.props;
        return ()=>{
            dispatch(sendJsonPost(loadWorkstationUrl, {}, (resp)=>{
                dispatch(renderData("workstationList", resp.results));
                setTimeout(() => {
                    const bs = new BScroll('.workstation-wrapper', {
                        probeType: 3,
                        click: true,
                        pullUpLoad: false
                    })
                    dispatch(renderData("workstationListBs", bs));
                }, 500);
            }))
        }
    }

    render() {
        const {renderReducer} = this.props;
        let proxyList = renderReducer.workstationList;
        if(proxyList == null){
            proxyList = [];
        }
        return (
            <div className="product-list workstation-wrapper" style={{ height: (window.innerHeight - 55) }}>
                <ul className="content order-list-ul">
                {
                    proxyList.map((record)=>{
                        return <WorkstationItem key={record.name} record={record}/>
                    })
                }
                </ul>
            </div>

        )
    }
}

export default connect(state => {
    return state;
})(WorkstationList)
