import React, { Component } from 'react'
import { connect } from 'react-redux';
import "./form-item.css"

class FormItem extends Component {
    render() {
        const { title, children } = this.props;
        return (
            <div className='form-item-container'>
                <div className='form-item-title'>
                    <span>{title}</span>
                </div>
                <div className='form-item-content'>
                    {children}
                </div>
            </div>
        )
    }
}


export default connect(state => {
    return state;
})(FormItem)
