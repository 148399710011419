import React, { Component } from 'react'

import { connect } from 'react-redux';

class WorkStationOrderItem extends Component {
    render() {
        const {record} = this.props;
        let workStation = record.workStation;
        return (
            <li className="li-card flex-column-div" key={record.id}>
                <div className="card-line">
                    <span className="bold-span font18">工位名称: {workStation == null ? "---" : workStation.name}</span>
                </div>
                <div className="card-line">
                    <span>类型: {record.workStationState}</span>
                </div>
                <div className="card-line">
                    <span>时间: {new Date(record.timestamp).Format("yyyy-MM-dd hh:mm:ss")}</span>
                </div>
            </li>
        )
    }
}

export default connect(state => {
    return state;
})(WorkStationOrderItem)

