import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendJsonPost } from '../../redux/common-actions/network-action'
import { workLogListUrl } from '../../constants';
import { appendRenderData, clearRenderData, renderData } from '../../redux/common-actions/render-action';
import { getFormValue, showErrorPrompt } from '../../utils/common-util';
import BScroll from "better-scroll"
import WorkLogItem from './WorkLogItem';
import { changeModalDisplay } from '../../redux/common-actions/modal-action';

var workLogListCurrentPage = 0;
var workListTotalPage = 0;
var workLogListBs = null

class WorkLogList extends Component {
    componentDidMount() {
        this.loadWorkLogList()();
        const bs = new BScroll('.wrapper', {
            probeType: 3,
            click: true,
            pullUpLoad: true,
            pullDownRefresh: {
                threshold: 50,
                stop: 0
            }
        })
        bs.on('pullingUp', this.handlePullUp());
        bs.on("pullingDown", this.handlePullDown())
        workLogListBs = bs;
    }

    loadWorkLogList() {
        const { dispatch, renderReducer, match } = this.props;
        return (page, pullUp, pullDown) => {
            let workStationId = getFormValue(match, "params", "workStationId");
            if (workStationId == null || workStationId === "") {
                return;
            }
            let workLogList = Object.assign({}, renderReducer["workLogList"]);
            let currentPage = 0;
            if (page != null) {
                currentPage = page.current;
            } else {
                if (workLogList.pager != null) {
                    currentPage = workLogList.pager.currentPage;
                }
            }
            dispatch(changeModalDisplay("loadingModal", true));
            dispatch(sendJsonPost(workLogListUrl, {workStationId, currentPage }, (resp) => {
                dispatch(changeModalDisplay("loadingModal", false));
                if (resp.code === 0) {
                    if(pullUp === true){
                        dispatch(appendRenderData("workLogList", resp));
                    }else{
                        dispatch(renderData("workLogList", resp));
                    }
                    
                    workLogListCurrentPage = resp.pager.currentPage;
                    workListTotalPage = resp.pager.totalPage;
                    setTimeout(() => {
                        if (pullUp === true) {
                            workLogListBs.finishPullUp();
                        }
                        if (pullDown === true) {
                            workLogListBs.finishPullDown()
                        }
                        workLogListBs.refresh();
                        // alert("Finished init better scroll.")
                    }, 500);
                } else {
                    showErrorPrompt(dispatch, resp);
                }
            }))
        }
    }

    handlePullUp() {
        return () => {
            //Not able to give a count, so do not determine count
            this.loadWorkLogList()({ current: workLogListCurrentPage + 1 }, true);
        }
    }

    handlePullDown() {
        const { dispatch } = this.props;
        return () => {
            console.log("Pulling down...");
            dispatch(clearRenderData("workLogList"))
            this.loadWorkLogList()(null, null, true);
        }
    }

    render() {
        const { renderReducer, formReducer } = this.props;
        console.log("Form data : ", formReducer);
        let dataList = [];
        if (renderReducer.workLogList != null && renderReducer.workLogList.results != null) {
            dataList = renderReducer.workLogList.results;
        }
        return (
            <div className="product-list wrapper" style={{ height: (window.innerHeight - 55) }}>
                <ul className="content order-list-ul">
                    {
                        dataList.map((record) => {
                            return <WorkLogItem key={record.id} record={record} />
                        })
                    }
                </ul>
            </div>

        )
    }
}

export default connect(state => {
    return state;
})(WorkLogList)
