import './App.css';
import "semantic-ui-css/semantic.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-photo-view/dist/index.css';

import store from './redux/store';
import { Route, Switch, Redirect } from 'react-router-dom'
import { HashRouter } from 'react-router-dom'
import {Provider} from 'react-redux'
import MainPage from './pages/MainPage'
import Login from './pages/Login'

function App() {
  
  return (
    <div className="App">
      <HashRouter>
        <Provider store={store}>
          <Switch>
            <Route path="/main" component={MainPage}></Route>
            <Route path="/login" component={Login}></Route>
            <Redirect to="/login" />
          </Switch>
        </Provider>
      </HashRouter>
    </div>

  );
}

export default App;
