import React, { Component } from 'react'
import { connect } from 'react-redux';
import "./detail.css"
import FormItem from '../../components/FormItem';
import { PhotoProvider, PhotoConsumer } from 'react-photo-view';
import { resolveImagePath } from '../../utils/common-util';

class DetailContent extends Component {
    render() {
        const { renderReducer } = this.props;
        let record = {};
        if (renderReducer.workStationDetail != null) {
            record = renderReducer.workStationDetail;
        }
        let equipment = {};
        if (record.equipment != null) {
            equipment = record.equipment;
        }
        let deviceInfo = {};
        if (record.deviceInfo != null) {
            deviceInfo = record.deviceInfo;
        }
        return (
            <li className='li-card flex-column-div'>
                <FormItem title="设备名称 :">
                    <span>{record.name}</span>
                </FormItem>
                <FormItem title="设备序列号 :">
                    <span>{record.deviceSerialLabel}</span>
                </FormItem>
                <FormItem title="控制器编号 :">
                    <span>{equipment.serial}</span>
                </FormItem>
                <FormItem title="设备型号 :">
                    <span>{equipment.model}</span>
                </FormItem>
                <FormItem title="生产厂商 :">
                    <span>{equipment.brand}</span>
                </FormItem>
                <FormItem title="生产时间 :">
                    <span>{equipment.boughtTime == null ? "-" : new Date(equipment.boughtTime).Format("yyyy-MM-dd")}</span>
                </FormItem>
                <FormItem title="设备铭牌 :">
                    <PhotoProvider>
                        <PhotoConsumer src={resolveImagePath(equipment.nameplate)} intro={"设备铭牌"}>
                            <img alt={"设备铭牌"} src={resolveImagePath(equipment.nameplate)} style={{ width: 200 }} />
                        </PhotoConsumer>
                    </PhotoProvider>
                </FormItem>
                <FormItem title="控制器型号 :">
                    <span>{equipment.controllerModel}</span>
                </FormItem>
                <FormItem title="控制器厂商 :">
                    <span>{equipment.controllerBrand}</span>
                </FormItem>
                <FormItem title="控制器铭牌 :">
                    <PhotoProvider>
                        <PhotoConsumer src={resolveImagePath(equipment.controllerNameplate)} intro={"控制器铭牌"}>
                            <img alt={"控制器铭牌"} src={resolveImagePath(equipment.controllerNameplate)} style={{ width: 200 }} />
                        </PhotoConsumer>
                    </PhotoProvider>
                </FormItem>
                <FormItem title="转发端口 :">
                    <span>{deviceInfo.forwardPort}</span>
                </FormItem>
                <FormItem title="4G卡号 :">
                    <span>{deviceInfo.cardSerial}</span>
                </FormItem>
            </li >
        )
    }
}


export default connect(state => {
    return state;
})(DetailContent)