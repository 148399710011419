
const initState = {hello : 1}//初始化状态

export default function testReducer(preState=initState,action){
    const {type} = action
	//根据type决定如何加工数据
	switch (type) {
        default:
			return preState
    }
}