import { connect } from 'react-redux'
import React, {Component, Fragment} from 'react';
import {formChange} from '../../redux/common-actions/form-action'
import {getFormValue} from '../../utils/common-util'
import { Input, Icon } from 'semantic-ui-react'

class AInput extends Component{
    inputChange = () => (event) => {
        const {dispatch, scope, name} = this.props;
        dispatch(formChange(scope, name, event.target.value))
    }
    handleIconClick(){
        const {iconClick} = this.props;
        return ()=>{
            if(iconClick != null){
                iconClick();
            }
        }
    }
    render(){
        const {formReducer, scope, name, icon, rightButton, password, disabled, placeholder, iconPosition} = this.props;
        let data = getFormValue(formReducer, scope, name, "");
        let inputParam = {};
        if(icon != null){
            inputParam.iconPosition = iconPosition == null ? "left" : iconPosition;
        }
        
        return <Input {...inputParam} style={{width : "100%"}} icon={rightButton == null ? null : rightButton}>
            {icon == null ? [] : <Icon name={icon} onClick={this.handleIconClick()}></Icon>}
            {password === true ? <input value={data} type="password" onChange={this.inputChange()}/> : 
            <input value={data} onChange={this.inputChange()} disabled={disabled} placeholder={placeholder}/>}
            {
                rightButton != null ? rightButton : <Fragment></Fragment>
            }
        </Input>
    }
}

export default connect(state => {
    return state;
})(AInput)
