import { MODAL_DISPLAY, MODAL_LOADING, MODAL_CONTENT } from "../../constants"
export const changeModalDisplay = (modalId, display) => {
    return {
        type : MODAL_DISPLAY,
        data : {
            modalId,
            display
        }
    }
}

export const changeModalLoading = (modalId, loading) => {
    return {
        type : MODAL_LOADING,
        data : {
            modalId,
            loading
        }
    }
}

export const changeModalContent = (modalId, content) => {
    return {
        type : MODAL_CONTENT,
        data : {
            modalId,
            content
        }
    }
}