/**
 * Constants
 */

export const siteChoiceArray = ["US", "GB", "DE", "FR", "IT", "ES", "JP"];
export const categoryChoiceArray = ["All", "Electronics", "Computers", "Smart Home", "Arts & Crafts", "Automotive"];
export const genderChoiceArray = ["Male", "Female"];

export const transformDropDownItem = (arr) => {
    let ret = [];
    arr.forEach((record)=>{
        ret.push({
            key : record,
            text : record,
            value : record
        })
    });
    return ret;
}


/**
 * Request URLs
 */
//Prod
export const reportWorkSystemUrl = "http://huaweiyun.sheepcool.com:10022/";
export const projectName = "report-work";

//Stage

// export const reportWorkSystemUrl = "http://scanz.sheepcool.com:13109/";
// export const projectName = "test"

//Dev
// export const reportWorkSystemUrl = "http://scanz.sheepcool.com:14088/";


export const fileServerUrl = "http://tengxunyun.sheepcool.com:10040/"


export const loginUserUrl = reportWorkSystemUrl + "user/login";
export const getUserSessionUrl = reportWorkSystemUrl + "user/get-user-session";
export const loadProxyUrl = reportWorkSystemUrl + "statistic/proxy-stats";
export const loadWorkstationUrl = reportWorkSystemUrl + "statistic/device-stats"
export const updateProgramUrl = reportWorkSystemUrl + "statistic/update-program";
export const deviceLogListUrl = reportWorkSystemUrl + "device-log/list"
export const monitorUpdatorUrl = reportWorkSystemUrl + "debug/monitor-updator"

export const organizationListUrl = reportWorkSystemUrl + "organization/list"

export const workStationListUrl = reportWorkSystemUrl + "work-station/list"
export const workStationDetailUrl = reportWorkSystemUrl + "work-station/detail"

export const editDeviceInfoUrl = reportWorkSystemUrl + "device-info/create"

export const editEquipmentUrl = reportWorkSystemUrl + "equipment/create"

export const workLogListUrl = reportWorkSystemUrl + "work-log/list"

export const listWorkStationOrderRecordUrl = reportWorkSystemUrl + "station-order-record/list"

export const uploadFileUrl = fileServerUrl + "data/upload"
export const uploadFilePrefixUrl = fileServerUrl + "data/fetch/"
export const resolveFileArrayUrl = fileServerUrl + "data/resolve"


/**
 * Actions
 */
export const INPUT_CHANGE = "INPUT_CHANGE";
export const CLEAR_FORM_SCOPE = "CLEAR_FORM_SCOPE";
export const SET_FORM_SCOPE = "SET_FORM_SCOPE"
export const MODAL_LOADING = "MODAL_LOADING"
export const MODAL_CONTENT = "MODAL_CONTENT"
export const MODAL_DISPLAY = "MODAL_DISPLAY";
export const RENDER_DATA = "RENDER_DATA"
export const RENDER_CLEAR = "RENDER_CLEAR"
export const APPEND_RENDER_DATA = "APPEND_RENDER_DATA";

/**
 * Translate table
 */

export const productStateTable = {
    Created : "待审核",
    Verified : "已审核"
}

export const translateProductState = (state)=>{
    let returnValue = (state == null || state === "") ? productStateTable["Created"] : productStateTable[state]
    return returnValue;
}

export const commissionStateTable = {
    Created : "待支付",
    Deposited : "已支付"
}

export const translateCommissionState = (state) => {
    let returnValue = (state == null || state === "") ? commissionStateTable["Created"] : commissionStateTable[state]
    return returnValue;
}

export const chargeStateTable = {
    Created : "待审核",
    Verified : "已审核",
    Rejected : "已驳回"
}

export const transactionReasons = {
    Refund : "Refund",
    Commission : "Commission",
    Distribute : "Distribute",
    Charge : "Charge",
    WithdrawApply : "Withdraw Apply",
    WithdrawCancel : "Withdraw Cancel",
    WithdrawReject : "Withdraw Reject"
}

export const withdrawStates = {
    Created : "Waiting for Approve",
    Verified : "Approved",
    Confirmed : "Transaction Made",
    Rejected : "Rejected",
    Canceled : "Canceled"
}

export const orderStates = {
    Created : "Confirming", //平台下单
    Verified : "Waiting for purchase", //商户确认
    Purchased : "Purchase Confirming", //用户购买
    Refund : "Waiting for feedback", //反全款
    Feedbacked : "Feedback Confirming", //留评
    CommissionPaid : "Finished", //返佣金
    Rejected : "Rejected", //未通过审核
    InvalidPurchase: "InvalidPurchase" , //购买不合规
    InvalidFeedback : "InvalidFeedback", //留评不合规
    Deleted : "Deleted", //已删除
    Canceled : "Canceled"
}

export const translateChargeState = (state) => {
    let returnValue = (state == null || state === "") ? chargeStateTable["Created"] : chargeStateTable[state]
    return returnValue;
}

