import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendJsonPost } from '../../redux/common-actions/network-action'
import ProxyItem from './ProxyItem';
import { loadProxyUrl } from '../../constants';
import { renderData } from '../../redux/common-actions/render-action';
import BScroll from "better-scroll"

class ProxyList extends Component {

    componentDidMount(){
        this.loadProxyRecords()();
    }

    loadProxyRecords(){
        const {dispatch} = this.props;
        return ()=>{
            console.log("Load url : ", loadProxyUrl);
            dispatch(sendJsonPost(loadProxyUrl, {}, (resp)=>{
                dispatch(renderData("proxyList", resp.results));
                setTimeout(() => {
                    const bs = new BScroll('.order-wrapper', {
                        probeType: 3,
                        click: true,
                        pullUpLoad: false
                    })
                    dispatch(renderData("productDetailBs", bs));
                }, 500);
            }))
        }
    }

    render() {
        const {renderReducer} = this.props;
        let proxyList = renderReducer.proxyList;
        if(proxyList == null){
            proxyList = [];
        }
        return (
            <div className="product-list order-wrapper" style={{ height: (window.innerHeight - 55) }}>
                <ul className="content order-list-ul">
                {
                    proxyList.map((record)=>{
                        return <ProxyItem key={record.name} record={record}/>
                    })
                }
                </ul>
            </div>

        )
    }
}

export default connect(state => {
    return state;
})(ProxyList)

