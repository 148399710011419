import React, { Component } from 'react'
import { connect } from 'react-redux';
import AInput from '../../components/AInput'
import { Icon } from 'semantic-ui-react'
import SModal from '../../components/SModal';
import { workStationListUrl, workStationDetailUrl } from '../../constants'
import { sendJsonPost } from '../../redux/common-actions/network-action';
import { getFormValue } from '../../utils/common-util';
import { renderData } from '../../redux/common-actions/render-action';
import { isArray } from 'lodash';
import { formChange } from '../../redux/common-actions/form-action';
import { changeModalDisplay } from '../../redux/common-actions/modal-action';

class WorkStationListModal extends Component {
    componentDidMount() {
        const { workStationId } = this.props;
        if(workStationId != null && workStationId !== ""){
            this.loadWorkStationDetail(workStationId);
        }

    }
    loadWorkStationDetail(workStationId){
        const {dispatch} = this.props;
        return ()=>{
            dispatch(sendJsonPost(workStationDetailUrl, {id : workStationId}, (resp)=>{
                if(resp.code === 0){
                    dispatch(formChange("searchWorkStation", "current", {
                        id : resp.result.id,
                        name : resp.result.name
                    }))
                }
            }))
        }
    }
    handleSearchClick() {
        const { dispatch, formReducer } = this.props;
        return () => {
            let keyword = getFormValue(formReducer, "searchWorkStation", "name", "");
            if (keyword === "") {
                return;
            }
            dispatch(sendJsonPost(workStationListUrl, { keyword }, (resp) => {
                if (resp.code === 0) {
                    dispatch(renderData("searchWorkStation", resp.results));
                }
            }));
        }
    }
    handleClickWorkStation(record) {
        const { dispatch } = this.props;
        return () => {
            dispatch(formChange("searchWorkStation", "current", {
                id : record.id,
                name : record.name
            }))
            dispatch(changeModalDisplay("work_station_list_modal", false))
        }
    }
    buildWorkStationList() {
        const { renderReducer } = this.props;
        if (!isArray(renderReducer.searchWorkStation)) {
            return [];
        }
        let ret = renderReducer.searchWorkStation.map((record) => {
            return <div key={record.id} style={{ paddingTop: 10 }}>
                <span style={{ fontSize: 18 }} onClick={this.handleClickWorkStation(record)}>{record.name}</span>
            </div>
        });
        return ret;
    }
    render() {
        let searchButton = <Icon name='search' inverted circular link onClick={this.handleSearchClick()} />;
        return (
            <SModal name="work_station_list_modal" header="搜索工位">
                <div>
                    <AInput scope="searchWorkStation" name="name" rightButton={searchButton} />
                    {this.buildWorkStationList()}
                </div>
            </SModal>
        )
    }
}
export default connect(state => {
    return state;
})(WorkStationListModal)