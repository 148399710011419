import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getFormValue } from '../../utils/common-util';
import { changeModalDisplay } from '../../redux/common-actions/modal-action';

class SModal extends Component {

    buildContent() {
        const { children, content, modalReducer, name } = this.props;
        let contentInStore = getFormValue(modalReducer, "content", name, "");
        if (children != null) {
            return children;
        } else {
            return (
                <p>
                    {
                        content == null ? contentInStore : content
                    }
                </p>
            )
        }
    }

    render() {
        const { modalReducer, name, dispatch, header, handleConfirm } = this.props;
        let display = getFormValue(modalReducer, "display", name, false);
        return (
            <Modal
                open={display}
                onClose={() => dispatch(changeModalDisplay(name, false))}>
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content>
                    {this.buildContent()}
                </Modal.Content>
                {
                    handleConfirm == null ? [] :
                        <Modal.Actions>
                            <Button
                                content="Confirm"
                                labelPosition='right'
                                icon='checkmark'
                                onClick={handleConfirm}
                                positive
                            />
                        </Modal.Actions>
                }
            </Modal>
        )
    }
}

export default connect(state => {
    return state;
})(SModal)
