import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { workStationDetailUrl } from '../../constants';
import { formChange, setFormScope } from '../../redux/common-actions/form-action';
import { sendJsonPost } from '../../redux/common-actions/network-action';
import { renderData } from '../../redux/common-actions/render-action';
import { getFormValue, showErrorPrompt } from '../../utils/common-util';
import DetailContent from './DetailContent';
import EditContent from './EditContent';

class EditWorkStationInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: 0
        }
    }

    componentDidMount() {
        this.loadWorkStationDetail()();
    }

    loadWorkStationDetail() {
        const { dispatch, match } = this.props;
        return () => {
            let workStationId = getFormValue(match, "params", "workStationId", "");
            if (workStationId === "") {
                return;
            }
            dispatch(sendJsonPost(workStationDetailUrl, { id : workStationId }, (resp) => {
                if (resp.code === 0) {
                    dispatch(renderData("workStationDetail", resp.result))
                    if (resp.result.equipment != null) {
                        dispatch(setFormScope("editEquipment", resp.result.equipment));
                    } else {
                        dispatch(formChange("editEquipment", "workStationId", resp.result.id));
                    }
                    if (resp.result.deviceInfo != null) {
                        dispatch(setFormScope("editDeviceInfo", resp.result.deviceInfo));
                    } else {
                        dispatch(formChange("editDeviceInfo", "deviceId", resp.result.deviceId));
                    }
                    this.setState({ page: 1 })
                } else {
                    showErrorPrompt(dispatch, resp);
                }
            }));
        }
    }

    handleClickEdit(page) {
        return () => {
            this.setState({
                page
            })
        }
    }

    buildContent() {
        const { renderReducer } = this.props;
        if (this.state.page === 0) {
            return <li className='li-card'>
                <span>Loading</span>
            </li>
        } else if (this.state.page === 1) {
            return <Fragment>
                <li className='li-card'>
                    <Button secondary size='tiny' onClick={this.handleClickEdit(2)}>编辑</Button>
                </li>
                <DetailContent />
            </Fragment>
        } else if (this.state.page === 2) {
            return <Fragment>
                <li className='li-card'>
                    <Button secondary size='tiny' onClick={this.handleClickEdit(1)}>返回</Button>
                </li>
                <EditContent record={renderReducer.workStationDetail} refreshFunc={this.loadWorkStationDetail()}/>
            </Fragment>
        }
    }

    render() {
        return (
            <ul className="page-container">
                {this.buildContent()}
            </ul>
        )
    }
}

export default connect(state => {
    return state;
})(EditWorkStationInfo)
