import React, { Component } from 'react'
import { connect } from 'react-redux';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { formChange } from '../../redux/common-actions/form-action';
import { getFormValue } from '../../utils/common-util';

class ADatePicker extends Component {
    onChange() {
        const { dispatch, scope, name } = this.props;
        return (event, data) => {
            if(data.value == null){
                dispatch(formChange(scope, name, 0));
            }else{
                dispatch(formChange(scope, name, data.value.getTime()));
            }
        }
    }

    render() {
        const { formReducer, scope, name, dateStrFormat } = this.props;
        let data = new Date();
        if (formReducer[scope] != null && formReducer[scope][name] != null) {
            let timestamp = getFormValue(formReducer, scope, name);
            if (!isNaN(parseInt(timestamp))) {
                data = new Date(formReducer[scope][name]);
            }
        }
        let format = "YYYY-MM-DD";
        if (dateStrFormat != null && dateStrFormat !== "") {
            format = dateStrFormat.toUpperCase();
        }
        return (
            <SemanticDatepicker onChange={this.onChange()} value={data} format={format} size="mini"/>
        )
    }
}

export default connect(state => {
    return state;
})(ADatePicker)
