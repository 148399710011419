import React, { Component } from 'react'
import { connect } from 'react-redux';
import {Button} from 'semantic-ui-react'
import { sendJsonPost } from '../../redux/common-actions/network-action'
import { updateProgramUrl } from '../../constants';
import { renderData } from '../../redux/common-actions/render-action';

class ProxyItem extends Component {

    handleUpdateClick(){
        const {dispatch, record} = this.props;
        return ()=>{
            if(record.port !== 0){
                dispatch(renderData("button" + record.name, true));
                dispatch(sendJsonPost(updateProgramUrl, {port : record.port}, (resp)=>{
                    dispatch(renderData("button" + record.name, false));
                }))
            }
        }
    }

    render() {
        const {record, renderReducer} = this.props;
        let style = {
            color : "red"
        }
        if(record.state === "online"){
            style.color = "green";
        }
        let buttonKey = "button" + record.name;
        let buttonDisable = renderReducer[buttonKey] === true;
        return (
            <li className="li-card flex-column-div">
                <div className="card-line">
                    <span>名称: {record.name}</span>
                </div>
                <div className="card-line">
                    <span style={style}>状态: {record.state}</span>
                </div>
                <div className="card-line">
                    <span>端口: {record.port}</span>
                </div>
                <div className="card-line">
                    {record.state === "online" ? <Button disabled={buttonDisable} primary size="tiny" onClick={this.handleUpdateClick()}>程序升级</Button> : []}
                </div>
            </li>
        )
    }
}

export default connect(state => {
    return state;
})(ProxyItem)
