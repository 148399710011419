import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Icon, Sidebar, Segment, Menu } from 'semantic-ui-react';
import { formChange } from '../../redux/common-actions/form-action';
import { getFormValue } from '../../utils/common-util';
import { Route, Switch } from 'react-router';
import ProxyList from '../ProxyList'
import WorkstationList from '../WorkstationList';
import DataList from '../DataList'
import SModal from '../../components/SModal'
import LoadingModal from '../../components/LoadingModal'
import Monitor from '../Monitor';
import SessionChecker from '../../components/SessionChecker';
import WorkStationInfoList from '../WorkStationInfoList';
import EditWorkStationInfo from '../EditWorkStationInfo';
import WorkLogList from '../WorkLogList';
import WorkStationOrderList from '../WorkStationOrderList'


class MainPage extends Component {
    changeSidebarVisible(visible) {
        const { dispatch, formReducer } = this.props;
        let sidebarVisible = getFormValue(formReducer, "header", "sidebarVisible");
        if (sidebarVisible == null) {
            sidebarVisible = false;
        }
        return () => {
            dispatch(formChange("header", "sidebarVisible", visible));
        }
    }
    handleNavClick(path) {
        const { history, dispatch } = this.props;
        return () => {
            dispatch(formChange("header", "sidebarVisible", false));
            history.push(path);

        }
    }
    render() {
        const { formReducer, history } = this.props;
        let sidebarVisible = getFormValue(formReducer, "header", "sidebarVisible");
        if (sidebarVisible == null) {
            sidebarVisible = false;
        }
        var mainContent = [];
        mainContent.push((
            <div className="main-header" key="header">
                <div className="header-icon-div">
                    <Icon name='content' className="header-icon" size="big" link onClick={this.changeSidebarVisible(true)} />
                </div>
                <div className="header-title-div">
                    <span className="header-title">Engineer</span>
                </div>
                <div className="header-icon-div">
                    {/* <Icon name='user' className="header-icon" size="big" link onClick={this.handleNavClick("/main/user-info")}/> */}
                </div>
                {/* <Grid style={{width : "100%"}}>
                    <Grid.Column floated='left' width={4}>
                        <div className="header-icon-div">
                            <Icon name='content' className="header-icon" size="big" link onClick={this.changeSidebarVisible()}/>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div className="header-icon-div">
                            <h1 className="header-title">Global E</h1>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <div className="header-icon-div">
                            <Icon name='user' className="header-icon" size="big" link />
                        </div>
                    </Grid.Column>
                </Grid> */}
            </div>));
        mainContent.push(
            <div className="main-content" key="route">
                <Switch>
                    <Route path="/main/proxy-list" component={ProxyList}></Route>
                    <Route path="/main/workstation-list" component={WorkstationList}></Route>
                    <Route path="/main/data-list/:workStationId" component={DataList}></Route>
                    <Route path="/main/data-list" component={DataList}></Route>
                    <Route path="/main/monitor" component={Monitor}></Route>
                    <Route path="/main/workstation-info-list" component={WorkStationInfoList}></Route>
                    <Route path="/main/edit-workstaion-info/:workStationId" component={EditWorkStationInfo}></Route>
                    <Route path="/main/work-log-list/:workStationId" component={WorkLogList}></Route>
                    <Route path="/main/station-order-record/:workStationId" component={WorkStationOrderList}></Route>
                </Switch>
            </div>
        );
        return (
            <div className="fullscreen-container" style={{ alignItems: "start" }}>
                <SessionChecker history={history} />
                <Sidebar.Pushable as={Segment} style={{ width: "100%" }}>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        icon='labeled'
                        inverted
                        vertical
                        visible={sidebarVisible}
                        onHide={this.changeSidebarVisible(false)}
                        width='thin'
                    >
                        <Menu.Item onClick={this.handleNavClick("/main/proxy-list")}>
                            <Icon name='home' />
                            Proxy
                        </Menu.Item>
                        <Menu.Item onClick={this.handleNavClick("/main/workstation-list")}>
                            <Icon name='home' />
                            WorkStation
                        </Menu.Item>
                        <Menu.Item onClick={this.handleNavClick("/main/data-list")}>
                            <Icon name='home' />
                            Data
                        </Menu.Item>
                        <Menu.Item onClick={this.handleNavClick("/main/monitor")}>
                            <Icon name='home' />
                            Monitor
                        </Menu.Item>
                        <Menu.Item onClick={this.handleNavClick("/main/workstation-info-list")}>
                            <Icon name='home' />
                            Info List
                        </Menu.Item>
                        <Menu.Item onClick={this.handleNavClick("/main/station-order-record")}>
                            <Icon name='home' />
                            Station Order Record
                        </Menu.Item>

                        
                    </Sidebar>
                    <Sidebar.Pusher className="main-pusher" as="div">
                        {mainContent}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
                <SModal name="networkWarning" header="Warning"></SModal>
                <LoadingModal />
            </div>
        )
    }
}

export default connect(state => {
    return state;
})(MainPage)
