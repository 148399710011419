import React, { Component, Fragment } from 'react'
import { getUserSessionUrl } from '../../constants';
import { sendJsonPost } from '../../redux/common-actions/network-action';
import { connect } from 'react-redux';

class SessionChecker extends Component {
    componentDidMount() {

    }

    checkUserSession() {
        const { dispatch, history } = this.props;
        dispatch(sendJsonPost(getUserSessionUrl, {}, (resp) => {
            if (resp.code === 0 && resp.result.role === "Admin") {

            } else {
                history.push("/login");
            }
        }));
    }

    render() {
        return (
            <Fragment></Fragment>
        )
    }
}

export default connect(state => {
    return state;
})(SessionChecker)
