import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'semantic-ui-react';
import "./item.css"

class WorkStationInfoItem extends Component {
    render() {
        const { record, history } = this.props;
        return (
            <li className='li-card item-container'
            >
                <span className='workstation-title'>{record.name}</span>
                <div className='flex-row-div'>
                    <div className='flex-stretch-div flex-center'>
                        <Button secondary size='tiny' 
                            onClick={() => { history.push("/main/data-list/" + record.id) }}>
                            数据
                        </Button>
                    </div>
                    <div className='flex-stretch-div flex-center'>
                        <Button secondary size='tiny' 
                            onClick={() => { history.push("/main/work-log-list/" + record.id) }}>
                            工时数据
                        </Button>
                    </div>
                    <div className='flex-stretch-div flex-center'>
                        <Button secondary size='tiny' onClick={() => { history.push("/main/edit-workstaion-info/" + record.id) }}>详情</Button>
                    </div>
                </div>
            </li>
        )
    }
}

export default connect(state => {
    return state;
})(WorkStationInfoItem)
