import { INPUT_CHANGE, CLEAR_FORM_SCOPE, SET_FORM_SCOPE } from "../../constants"

const initState = {

}
export default function formReducer(preState=initState,action){
    const {type, data} = action
	switch (type) {
        case INPUT_CHANGE : {
            var column = {};
            column[action.data.name] = action.data.value;
            var temp = Object.assign({}, preState[action.data.scope], column);
            var newState = {};
            newState[action.data.scope] = temp;
            return Object.assign({}, preState, newState)
        }
        case CLEAR_FORM_SCOPE : {
            let temp = {};
            temp[data.scope] = {}
            return Object.assign({}, preState, temp)
        }
        case SET_FORM_SCOPE : {
            let temp = {};
            temp[data.scope] = data.value;
            return Object.assign({}, preState, temp)
        }
        default:
			return preState
    }
}