import { isArray } from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SDropdown from '../../components/SDropdown';
import { organizationListUrl, workStationListUrl } from '../../constants'
import { sendJsonPost } from '../../redux/common-actions/network-action';
import { renderData } from '../../redux/common-actions/render-action';
import { getFormValue } from '../../utils/common-util';
import WorkStationInfoItem from './WorkStationInfoItem';

class WorkStationInfoList extends Component {

    constructor(props){
        super(props);
        this.state = {page : 0};
    }

    componentDidMount() {
        this.loadOrganizationList()();
    }

    loadOrganizationList() {
        const { dispatch } = this.props;
        return () => {
            dispatch(sendJsonPost(organizationListUrl, { customizedPageSize: 999 }, (resp) => {
                if (resp.code === 0) {
                    dispatch(renderData("organizationList", resp.results));
                }
            }));
        }
    }

    loadWorkStationList() {
        const { dispatch } = this.props;
        return (organizationId) => {
            this.setState({page : 1});
            if(organizationId === ""){
                return;
            }
            dispatch(sendJsonPost(workStationListUrl, { organizationId, customizedPageSize: 999 }, (resp) => {
                if (resp.code === 0) {
                    dispatch(renderData("workStationList", resp.results));
                    this.setState({page : 2});
                }
            }));
        }
    }



    buildOrgSelection() {
        const { renderReducer } = this.props;
        let ret = [];
        if (isArray(renderReducer["organizationList"])) {
            ret = renderReducer["organizationList"].map((record) => {
                return {
                    key: record.id,
                    text: record.name,
                    value: record.id
                }
            });
        }
        return ret;
    }

    buildWorkStationList(){
        const { renderReducer, history } = this.props;
        let ret = [];
        if(isArray(renderReducer["workStationList"])){
            ret = renderReducer["workStationList"].map((record)=>{
                return <WorkStationInfoItem record={record} key={record.id} history={history}/>
            })
        }
        return ret;
    }

    buildContent(){
        if(this.state.page === 0){
            return <li className='li-card'><h1>请选择组织</h1></li>
        }if(this.state.page === 1){
            return <li className='li-card'><h1>载入中</h1></li>
        }if(this.state.page === 2){
            return this.buildWorkStationList();
        }

    }

    render() {
        return (
            <ul className="page-container">
                <li className='li-card'>
                    <SDropdown scope="wsInfoList" name="organizationId" items={this.buildOrgSelection()} 
                        changeHook={this.loadWorkStationList()}/>
                </li>
                { this.buildContent() }
            </ul>
        )
    }
}

export default connect(state => {
    return state;
})(WorkStationInfoList)