import { isArray } from 'lodash';
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { monitorUpdatorUrl } from '../../constants';
import { sendJsonPost } from '../../redux/common-actions/network-action';
import { renderData } from '../../redux/common-actions/render-action';
import { showErrorPrompt } from '../../utils/common-util';
import "./monitor.css"

class Monitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intervalId: null
        }
    }
    componentDidMount() {
        this.loadOrderInfo()();
    }
    loadOrderInfo() {
        const { dispatch } = this.props;
        let data = {
            "orderIdList": ["62567589e7ba177d90c87343"]
        };
        return () => {
            dispatch(sendJsonPost(monitorUpdatorUrl, data, (resp) => {
                if (resp.code === 0) {
                    dispatch(renderData("monitorPage", resp.results));
                } else {
                    showErrorPrompt(dispatch, resp);
                }
            }))
        }
    }
    buildWorkStation(stationList) {
        return <div className='padding-div monitor-container'>
            {
                stationList.map(station => {
                    return <Fragment key={station.workStationId}>
                        <span className='monitor-title'>
                            {station.workStation == null ? station.workStationId
                                : (station.workStation.name + "(" + station.workStationId + ")")}</span>
                        <div className='padding-div monitor-container'>
                            <span>当前加工数 : {station.count}</span>
                            <span>已完成加工数 : {station.finishedCount}</span>
                            <span>工人ID : {station.currentWorkerId}</span>
                            <span>上工状态 : {station.state}</span>
                            <span>工位ID : {station.workStationId}</span>
                            <span>最后更新时间 : {new Date(station.lastUpdate).Format("yyyy-MM-dd hh:mm")}</span>
                            {station.workStation == null ? []
                                : <span>WorkStation 工人ID : {station.workStation.workerId}</span>
                            }
                            {
                                station.productInfoList == null ? [] :
                                    station.productInfoList.map((product) => {
                                        return <span key={product.productName}>{product.productName} : {product.coefficient}</span>
                                    })
                            }
                        </div>

                    </Fragment>
                })
            }
        </div>
    }
    buildStaionOrderMap(stationOrderMap) {
        return <div className='padding-div monitor-container'>
            {Object.keys(stationOrderMap).map((key) => {
                return <span key={key}>{key + " : " + stationOrderMap[key]}</span>
            })}
        </div>
    }
    buildContent() {
        const { renderReducer } = this.props;
        let list = [];
        if (isArray(renderReducer.monitorPage)) {
            list = renderReducer.monitorPage;
        }
        return list.map((record) => {
            let entity = record.entity;
            let cache = record.cache;
            return <li className='li-card monitor-container' key={entity.id}>
                <span className='monitor-title'>{entity.orderSerial}({entity.id})</span>
                {
                    entity.productList == null ? [] :
                        entity.productList.map(product => {
                            return <span key={product.productName}>
                                {product.productName} : {product.targetAmount} / {product.actualAmount}
                            </span>
                        })
                }
                <span>状态 ： {entity.orderState}</span>
                <div className='padding-div'>
                    {entity.workFlow.map((workFlow) => {
                        return <Fragment key={workFlow.name}>
                            <span className='monitor-title'>{workFlow.name}</span>
                            {this.buildWorkStation(workFlow.stationList)}
                        </Fragment>
                    })}
                </div>
                <span className='monitor-title'>
                    Cache :
                </span>
                <div className='padding-div'>
                    {
                        cache == null ? []
                            : <Fragment>
                                {this.buildWorkStation(cache)}
                            </Fragment>
                    }
                </div>
                <span className='monitor-title'>
                    Station Map :
                </span>
                {
                    record.stationOrderMap == null ? []
                        : this.buildStaionOrderMap(record.stationOrderMap)
                }
            </li>
        })
    }
    render() {
        return (
                <ul className="page-container">
                    {this.buildContent()}
                </ul>
        )
    }
}

export default connect(state => {
    return state;
})(Monitor)