import React, { Component } from 'react'
import { connect } from 'react-redux';
import FormItem from '../../components/FormItem';
import "./detail.css"
import AInput from '../../components/AInput';
import ADatePicker from '../../components/ADatePicker'
import SUpload from '../../components/SUpload'
import { Button } from 'semantic-ui-react';
import { sendJsonPost } from '../../redux/common-actions/network-action';
import { editDeviceInfoUrl, editEquipmentUrl } from '../../constants';
import { showErrorPrompt } from '../../utils/common-util';

class EditContent extends Component {

    handleSubmitDeviceInfo(){
        const {dispatch, formReducer, refreshFunc} = this.props;
        return ()=>{
            dispatch(sendJsonPost(editDeviceInfoUrl, formReducer.editDeviceInfo, (resp)=>{
                if(resp.code === 0){
                    if(refreshFunc != null){
                        refreshFunc();
                    }
                }else{
                    showErrorPrompt(dispatch, resp);
                }
            }));
        }
    }

    handleSubmitEquipment(){
        const {dispatch, formReducer, refreshFunc} = this.props;
        return ()=>{
            dispatch(sendJsonPost(editEquipmentUrl, formReducer.editEquipment, (resp)=>{
                if(resp.code === 0){
                    if(refreshFunc != null){
                        refreshFunc();
                    }
                }else{
                    showErrorPrompt(dispatch, resp);
                }
            }));
        }
    }

    render() {
        const { record } = this.props;
        let workstation = record == null ? {} : record;
        return (
            <li className='li-card flex-column-div'>
                <span className='detail-title'>基础信息({workstation.name})</span>
                <FormItem title="控制器编号 :">
                    <AInput scope="editEquipment" name="serial" />
                </FormItem>
                <FormItem title="设备型号 :">
                    <AInput scope="editEquipment" name="model" />
                </FormItem>
                <FormItem title="生产厂商 :">
                    <AInput scope="editEquipment" name="brand" />
                </FormItem>
                <FormItem title="生产日期 :">
                    <ADatePicker scope="editEquipment" name="boughtTime" />
                </FormItem>
                <FormItem title="设备铭牌 :">
                    <SUpload scope="editEquipment" name="nameplate" />
                </FormItem>
                <FormItem title="控制器型号 :">
                    <AInput scope="editEquipment" name="controllerModel" />
                </FormItem>
                <FormItem title="控制器厂商 :">
                    <AInput scope="editEquipment" name="controllerBrand" />
                </FormItem>
                <FormItem title="控制器铭牌 :">
                    <SUpload scope="editEquipment" name="controllerNameplate" />
                </FormItem>
                <FormItem>
                    <Button secondary size="tiny" onClick={this.handleSubmitEquipment()}>更新</Button>
                </FormItem>
                <span className='detail-title'>设备信息</span>
                <FormItem title="4G卡号 :">
                    <AInput scope="editDeviceInfo" name="cardSerial" />
                </FormItem>
                <FormItem>
                    <Button secondary size="tiny" onClick={this.handleSubmitDeviceInfo()}>更新</Button>
                </FormItem>
            </li>
        )
    }
}

export default connect(state => {
    return state;
})(EditContent)