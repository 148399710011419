import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendJsonPost } from '../../redux/common-actions/network-action'
import { deviceLogListUrl } from '../../constants';
import { appendRenderData, clearRenderData, renderData } from '../../redux/common-actions/render-action';
import { getFormValue, showErrorPrompt } from '../../utils/common-util';
import BScroll from "better-scroll"
import DeviceLogItem from './DeviceLogItem';
import { changeModalContent, changeModalDisplay } from '../../redux/common-actions/modal-action';
import ADatePicker from '../../components/ADatePicker';
import ADateTimePicker from '../../components/ADateTimePicker';
import { Button } from 'semantic-ui-react';

var dataListCurrentPage = 0;
var dataListTotalPage = 0;
var dataListBs = null

class DataList extends Component {
    componentDidMount() {
        this.loadDeviceData()();
        const bs = new BScroll('.wrapper', {
            probeType: 3,
            click: true,
            pullUpLoad: true,
            pullDownRefresh: {
                threshold: 50,
                stop: 0
            }
        })
        bs.on('pullingUp', this.handlePullUp());
        bs.on("pullingDown", this.handlePullDown())
        dataListBs = bs;
    }

    loadDeviceData() {
        const { dispatch, formReducer, renderReducer, match } = this.props;
        return (page, pullUp, pullDown) => {
            let workStationId = getFormValue(match, "params", "workStationId");
            let start = getFormValue(formReducer, "dataFilter", "start");
            let end = getFormValue(formReducer, "dataFilter", "end");
            let keyword = getFormValue(formReducer, "dataList", "keyword", "");
            if (workStationId == null || workStationId === "") {
                return;
            }
            let dataList = Object.assign({}, renderReducer["dataList"]);
            let currentPage = 0;
            if (page != null) {
                currentPage = page.current;
            } else {
                if (dataList.pager != null) {
                    currentPage = dataList.pager.currentPage;
                }
            }
            dispatch(changeModalDisplay("loadingModal", true));
            dispatch(sendJsonPost(deviceLogListUrl, { keyword, workStationId, currentPage, start, end }, (resp) => {
                dispatch(changeModalDisplay("loadingModal", false));
                if (resp.code === 0) {
                    if(pullUp === true){
                        dispatch(appendRenderData("dataList", resp));
                    }else{
                        dispatch(renderData("dataList", resp));
                    }
                    
                    dataListCurrentPage = resp.pager.currentPage;
                    dataListTotalPage = resp.pager.totalPage;
                    setTimeout(() => {
                        if (pullUp === true) {
                            dataListBs.finishPullUp();
                        }
                        if (pullDown === true) {
                            dataListBs.finishPullDown()
                        }
                        dataListBs.refresh();
                        // alert("Finished init better scroll.")
                    }, 500);
                } else {
                    showErrorPrompt(dispatch, resp);
                }
            }))
        }
    }

    handlePullUp() {
        return () => {
            //Not able to give a count, so do not determine count
            this.loadDeviceData()({ current: dataListCurrentPage + 1 }, true);
        }
    }

    handlePullDown() {
        const { dispatch } = this.props;
        return () => {
            console.log("Pulling down...");
            dispatch(clearRenderData("dataList"))
            this.loadDeviceData()(null, null, true);
        }
    }

    handleSearchClick() {
        const { dispatch } = this.props;
        return () => {
            dispatch(renderData("dataList", {}))
            this.loadDeviceData()();
        }
    }

    render() {
        const { renderReducer, formReducer } = this.props;
        let dataList = [];
        if (renderReducer.dataList != null && renderReducer.dataList.results != null) {
            dataList = renderReducer.dataList.results;
        }
        return (
            <div className="product-list wrapper" style={{ height: (window.innerHeight - 55) }}>
                <ul className="content order-list-ul">
                    <li className="li-card flex-column-div">
                        <div className='flex-row-div'>
                            <div className='flex-stretch-div flex-center'>
                                <ADateTimePicker scope="dataFilter" name="start" placeHolder="开始时间" />
                            </div>
                            <div className='flex-stretch-div flex-center'>
                                <ADateTimePicker scope="dataFilter" name="end" placeHolder="结束时间" />
                            </div>
                        </div>
                        <div className='flex-row-div flex-stretch-div flex-center top10'>
                            <Button secondary onClick={this.loadDeviceData()}>检索</Button>
                        </div>
                    </li>
                    {
                        dataList.map((record) => {
                            return <DeviceLogItem key={record.id} record={record} />
                        })
                    }
                </ul>
            </div>

        )
    }
}

export default connect(state => {
    return state;
})(DataList)
