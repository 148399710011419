import {createStore, applyMiddleware, combineReducers} from 'redux'
import thunk from 'redux-thunk'
import testReducer from './reducers/TestReducer'
import formReducer from './reducers/FormReducer'
import modalReducer from './reducers/Modalreducer'
import renderReducer from './reducers/RenderReducer'

let reducers = combineReducers({testReducer, formReducer, modalReducer, renderReducer});

export default createStore(reducers, applyMiddleware(thunk))


// export default createStore(countReducer)
