import { RENDER_DATA, RENDER_CLEAR, APPEND_RENDER_DATA } from "../../constants"
export const renderData = (scope, value) => {
    return {
        type : RENDER_DATA,
        data : {
            scope,
            value
        }
    }
}

export const appendRenderData = (scope, value) => {
    return {
        type : APPEND_RENDER_DATA,
        data : {
            scope,
            value
        }
    }
}

export const clearRenderData = (scope) => {
    return {
        type : RENDER_CLEAR,
        data : {scope}
    }
}