import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendJsonPost } from '../../redux/common-actions/network-action'
import { listWorkStationOrderRecordUrl } from '../../constants';
import { renderData } from '../../redux/common-actions/render-action';
import BScroll from "better-scroll"
import WorkstationItem from './WorkStationOrderItem';
import WorkStationListModal from './WorkStationListModal';
import { changeModalDisplay } from '../../redux/common-actions/modal-action';
import { getFormValue, showErrorPrompt } from '../../utils/common-util';
import { Button } from 'semantic-ui-react';
import { isArray } from 'lodash';
import { formChange } from '../../redux/common-actions/form-action';

class WorkStationOrderList extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        this.loadWorkstationRecords()();
        console.log("Show modal");
    }

    loadWorkstationRecords() {
        const { dispatch, formReducer } = this.props;
        let selectedWorkStation = getFormValue(formReducer, "searchWorkStation", "current", {});

        return () => {
            dispatch(sendJsonPost(listWorkStationOrderRecordUrl, 
                {workStationId :selectedWorkStation.id, orderBy : "timestamp", orderDirection : "desc"}, (resp) => {
                if(resp.code === 0){
                    dispatch(renderData("workStationOrderList", resp.results));
                }else{
                    showErrorPrompt(dispatch, resp);
                }
            }))
        }
    }

    handleClickStationName(){
        const {dispatch} = this.props;
        return ()=>{
            dispatch(changeModalDisplay("work_station_list_modal", true))
        }
    }

    render() {
        const { formReducer, renderReducer, dispatch } = this.props;
        let selectedWorkStation = getFormValue(formReducer, "searchWorkStation", "current", {});
        let stationOrderRecordList = [];
        if(isArray(renderReducer.workStationOrderList)){
            stationOrderRecordList = renderReducer.workStationOrderList;
        }
        return (
            <div className="" style={{ height: (window.innerHeight - 55), overflow : "scroll" }}>
                <ul className="none-bs-ul">
                    <div className="li-card card-line flex-center" >
                        <span className="bold-span font18" style={{display : "flex", flex : 5}} onClick={this.handleClickStationName()}>
                            工位名称: {selectedWorkStation.name == null ? "---" : selectedWorkStation.name}
                        </span>
                        <div>
                            <Button size="mini" secondary onClick={()=>{dispatch(formChange("searchWorkStation", "current", {}))}}>清除</Button>
                            <Button size="mini" primary onClick={this.loadWorkstationRecords()}>搜索</Button>
                        </div>
                    </div>
                    {
                        stationOrderRecordList.map((record) => {
                            return <WorkstationItem key={record.id} record={record}/>
                        })
                    }
                </ul>
                <WorkStationListModal />
            </div>

        )
    }
}

export default connect(state => {
    return state;
})(WorkStationOrderList)
