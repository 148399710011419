import { INPUT_CHANGE, CLEAR_FORM_SCOPE, SET_FORM_SCOPE } from "../../constants"
export const formChange = (scope, name, value) => {
    return {
        type : INPUT_CHANGE,
        data : {
            scope,
            name,
            value
        }
    }
}

export const clearFormScope = (scope) => {
    return {
        type : CLEAR_FORM_SCOPE,
        data : {scope}
    }
}

export const setFormScope = (scope, value) => {
    return {
        type : SET_FORM_SCOPE,
        data : {scope, value}
    }
}