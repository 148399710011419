import React, { Component } from 'react'

import { connect } from 'react-redux';

class DeviceLogItem extends Component {
    render() {
        const {record} = this.props;
        return (
            <li className="li-card flex-column-div">
                <div className="card-line">
                    <span className="bold-span">时间: {record.timeStr}</span>
                </div>
                <div className="card-line">
                    <span className="bold-span">I/O计数: {record.counting}</span>
                </div>
                <div className="card-line">
                    <span className="bold-span">电量: {record.acPower.toFixed(2)}</span>
                </div>
            </li>
        )
    }
}

export default connect(state => {
    return state;
})(DeviceLogItem)

