import React, { Component } from 'react'

import { connect } from 'react-redux';
import { getFormValue } from '../../utils/common-util';

class WorkstationItem extends Component {
    render() {
        const {record} = this.props;
        let count = getFormValue(record, "latestLog", "counting");
        let acCurrent = getFormValue(record, "latestLog", "current");
        let timestamp = getFormValue(record, "latestLog", "timestamp", 0);
        let acPower = getFormValue(record, "latestLog", "acPower", 0);
        return (
            <li className="li-card flex-column-div">
                <div className="card-line">
                    <span className="bold-span">名称: {record.name}</span>
                </div>
                <div className="card-line">
                    <span className="bold-span font18">设备名称: {record.deviceRecord.name}</span>
                </div>
                <div className="card-line">
                    <span>生产计数: {count}</span>
                </div>
                <div className="card-line">
                    <span>电流值: {acCurrent}</span>
                </div>
                <div className="card-line">
                    <span>累计电量: {acPower}</span>
                </div>
                <div className="card-line">
                    <span>最后更新时间: {new Date(timestamp).Format("yyyy-MM-dd hh:mm:ss")}</span>
                </div>
            </li>
        )
    }
}

export default connect(state => {
    return state;
})(WorkstationItem)

